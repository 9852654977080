<template>
  <button
    @click="$emit('click')"
    :type="type"
    class="relative px-12 py-3 bg-primary-green text-white font-semibold rounded-xl hover:bg-opacity-75 focus:outline-none focus:ring focus:ring-primary-green focus:ring-opacity-50 transition ease-in duration-150"
    :disabled="loading"
  >
    <div
      v-if="loading"
      class="absolute bg-primary-green inset-0 flex items-center justify-center"
    >
      <LoadingSpinner class="text-white" />
    </div>
    <slot></slot>
  </button>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
  },
  components: { LoadingSpinner },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style></style>
