<template>
  <div>
    <div class="relative flex bg-white rounded-full">
      <div
        class="absolute inset-y-0 left-0 w-1/2 bg-primary-orange rounded-full transform transition ease-out duration-150"
        :class="{ 'translate-x-full': mode === 'speech' }"
      ></div>
      <button
        type="button"
        @click="mode = 'text'"
        class="flex-1 py-3 text-sm md:text-base font-medium rounded-full z-10 focus:outline-none"
        :class="mode === 'text' ? 'text-white' : 'text-primary-orange'"
      >
        <i class="fas fa-font"></i> Type to Search
      </button>
      <button
        type="button"
        @click="mode = 'speech'"
        class="flex-1 py-3 text-sm md:text-base font-medium rounded-full z-10 focus:outline-none"
        :class="mode === 'speech' ? 'text-white' : 'text-primary-orange'"
      >
        <i class="fas fa-microphone"></i> Speech to Text
      </button>
    </div>
    <div
      class="mt-6 flex items-stretch rounded-lg shadow-lg"
      v-if="mode === 'text'"
    >
      <div class="flex-1">
        <input
          v-model="searchQuery"
          class="w-full h-full px-3 rounded-l-lg focus:ring focus:ring-primary-orange focus:ring-opacity-50 transition ease-out duration-150"
          type="text"
          placeholder="Search"
        />
      </div>
      <button
        @click="search"
        type="button"
        class="relative flex-shrink-0 bg-primary-green text-white px-5 py-3 rounded-r-lg focus:ring focus:ring-primary-orange focus:ring-opacity-50 transition ease-out duration-150 overflow-hidden"
      >
        <div
          v-if="searching"
          class="absolute bg-primary-green inset-0 flex items-center justify-center"
        >
          <LoadingSpinner class="text-white" />
        </div>
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </button>
    </div>
    <div v-if="mode === 'speech'" class="mt-6">
      <div class="flex items-stretch rounded-lg">
        <button
          v-if="!(speaking || audioSrc)"
          type="button"
          class="flex items-center py-1 px-5 space-x-4 font-medium text-white bg-primary-green w-full rounded-lg hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-green focus:ring-opacity-50 transition ease-in duration-150"
          @click="record"
        >
          <svg
            class="h-10 w-10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <circle
              cx="12"
              cy="12"
              r="9"
              stroke="currentColor"
              stroke-width="2"
            />
            <circle cx="12" cy="12" r="6" fill="currentColor" />
          </svg>
          <span>Press to Speak</span>
        </button>
        <button
          v-if="speaking && !audioSrc"
          type="button"
          class="flex items-center py-1 px-5 space-x-4 font-medium text-white bg-primary-green w-full rounded-lg hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-green focus:ring-opacity-50 transition ease-in duration-150"
          @click="stop"
        >
          <svg
            class="h-10 w-10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <circle
              cx="12"
              cy="12"
              r="9"
              stroke="currentColor"
              stroke-width="2"
            />
            <rect
              x="7"
              y="7"
              width="10"
              height="10"
              rx="2"
              fill="currentColor"
            />
          </svg>
          <span class="animate-pulse">Listening...</span>
        </button>
        <div
          v-if="!speaking && audioSrc"
          class="relative flex justify-between py-1 px-3 space-x-4 font-medium text-primary-green bg-white w-full rounded-lg border-2 border-primary-green transition ease-in duration-150 overflow-hidden"
        >
          <button
            @click="togglePlayback"
            type="button"
            class="flex-shrink-0 block bg-white text-primary-green focus:outline-none"
          >
            <svg
              v-if="!playing"
              class="h-10 w-10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle
                cx="12"
                cy="12"
                r="9"
                stroke="currentColor"
                stroke-width="2"
              />
              <path
                d="M16.5 11.134a1 1 0 010 1.732l-6 3.464a1 1 0 01-1.5-.866V8.536a1 1 0 011.5-.866l6 3.464z"
                fill="currentColor"
              />
            </svg>
            <svg
              v-else
              class="h-10 w-10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle
                cx="12"
                cy="12"
                r="9"
                stroke="currentColor"
                stroke-width="2"
              />
              <rect
                x="9"
                y="7"
                width="2"
                height="10"
                rx="1"
                fill="currentColor"
              />
              <rect
                x="13"
                y="7"
                width="2"
                height="10"
                rx="1"
                fill="currentColor"
              />
            </svg>
          </button>
          <div class="flex-1 flex-shrink-1">
            <av-bars
              :canv-width="390"
              :canv-height="40"
              ref-link="recordedAudio"
              :symmetric="true"
              :bar-space="2"
              :bar-color="`#128807`"
            />
          </div>
          <button
            @click="deleteRecording"
            type="button"
            class="absolute inset-y-0 right-0 bg-white block px-4 py-1 border-primary-green text-opacity-50 hover:opacity-100 transition ease-in duration-150 focus:outline-none"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
        <div v-if="blob" id="audio" class="hidden">
          <audio id="recorded-audio" ref="recordedAudio">
            <source :src="audioSrc" type="audio/ogg" />
          </audio>
        </div>
      </div>
      <div v-if="blob" class="mt-4">
        <AppButton
          @click="search"
          class="w-full flex items-center space-x-4 justify-center"
        >
          <i class="fas fa-search"></i><span>Search</span>
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    AppButton,
    LoadingSpinner,
  },
  data: () => ({
    mode: "text",
    searchQuery: "",
    speaking: false,
    recorder: null,
    blob: null,
    audioSrc: null,
    playing: false,
    searchResults: []
  }),
  watch: {
    mode() {
      this.speaking = false;
    },
    playing(val) {
      if (val) {
        this.$refs.recordedAudio.addEventListener(
          "ended",
          this.playEndListener
        );
      } else {
        this.$refs.recordedAudio.removeEventListener(
          "ended",
          this.playEndListener
        );
      }
    },
  },
  created() {},
  computed: {
    searching() {
      return this.$store.state.searching;
    },
  },
  methods: {
    playEndListener(e) {
      this.$refs.recordedAudio.currentTime = 0;
      this.playing = false;
    },
    record() {
      this.playing = false;
      this.blob = null;
      var device = navigator.mediaDevices.getUserMedia({ audio: true });
      var items = [];
      device
        .then(stream => {
          this.speaking = true;
          this.recorder = new MediaRecorder(stream);
          this.recorder.ondataavailable = e => {
            items.push(e.data);
            if (this.recorder.state === "inactive") {
              this.blob = new Blob(items, { type: "audio/ogg" });
              this.audioSrc = URL.createObjectURL(this.blob);
              stream.getTracks()[0].stop();
            }
          };
          this.recorder.start();
        })
        .catch(err => {
          if (err.name && err.name === "NotAllowedError") {
            this.$error(
              "Permission Error",
              "We couldn't find your microphone. Please allow our website to access your microphone."
            );
          }
        });
    },
    stop() {
      this.recorder.stop();
      this.speaking = false;
    },
    toggleRecord() {
      if (this.speaking) {
        this.stop();
        return;
      }
      this.record();
    },
    playRecording() {
      if (this.$refs.recordedAudio) {
        this.$refs.recordedAudio.play();
        this.playing = true;
      }
    },
    stopPlayback() {
      if (this.$refs.recordedAudio) {
        this.$refs.recordedAudio.pause();
        this.playing = false;
      }
    },
    deleteRecording() {
      this.blob = null;
      this.audioSrc = null;
      this.speaking = false;
    },
    togglePlayback() {
      if (this.playing) {
        this.stopPlayback();
        return;
      }
      this.playRecording();
    },
    search() {
      // let promise =
      //   this.mode === "text"
      //     ? this.$store.dispatch("textSearch", this.searchQuery)
      //     : this.$store.dispatch("voiceSearch", this.blob);

      // promise
      //   .then(() => {
      //     if (this.$route.name !== "Search") this.$router.push("/search");
      //   })
      //   .catch(() => this.$error());
      try {       
        this.$store.state.searchQuery = this.searchQuery;
        const response = this.$http.get(
          "http://api.yesmyright.com/questions/?word="+this.searchQuery
        ).then(response => {
          this.searchResults = response.data.data;
          this.$store.state.result = this.searchResults;
        });

        if (this.$route.name !== "Search") this.$router.push("/search");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
